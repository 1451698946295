import {useState, useEffect, useRef} from 'react';
import socket from '../../socket';
import ACTIONS from '../../socket/actions';
import { useHistory } from "react-router-dom";
import {v4} from 'uuid';
import * as uuid from "uuid";

export default function Main() {
    const nameFromLocalStorage = localStorage.getItem('name');

  const history = useHistory();
  const [rooms, updateRooms] = useState([]);
  const rootNode = useRef();

  const [myName, setMyName] = useState('');
  const [mySocketId, setMySocketId] = useState('');
  const [connectedClients, setConnectedClients] = useState([]);

  useEffect(() => {
      let name;
      if (nameFromLocalStorage) {
          name = nameFromLocalStorage
      } else {
          window.focus();
          name = prompt('Your name is:');
          localStorage.setItem('name', name)
      }

        socket.emit(ACTIONS.INIT_CONNECT, {nickName: name});
        socket.on(ACTIONS.INIT_CONNECT, myInfo => {
            setMyName(myInfo.nickName);
            setMySocketId(myInfo.socketId);
        });

        socket.on(ACTIONS.SHARE_CLIENTS, ({clients = {}} = {}) => {
            if (rootNode.current) {
                const cl = Object.keys(clients).map(item => ({socketId: item, ...clients[item]}));
                setConnectedClients(cl);
            }
        });

        socket.on(ACTIONS.CALL, ({from, roomId}) => {
            window.focus();
            const isAccepted = window.confirm(`${from.nickName} is calling you. Press OK to accept`);

            if (!isAccepted) {
                socket.emit(ACTIONS.CALL_DENIED, {to: from.socketId});
                return;
            }

            history.push(`/room/${roomId}`);
        })

        socket.on(ACTIONS.REQUEST_TO_JOIN_RESPONSE, ({isAccepted, roomId}) => {

          if (isAccepted) {
              history.push(`/room/${roomId}`);
          } else {
              window.alert('Your request is denied')
          }
        })

      return () => {
            socket.off(ACTIONS.INIT_CONNECT)
            socket.off(ACTIONS.SHARE_CLIENTS)
            socket.off(ACTIONS.CALL)
            socket.off(ACTIONS.REQUEST_TO_JOIN_RESPONSE)
      }

  }, [nameFromLocalStorage]);

  const call = (to) => {
      const roomId = uuid.v4();
      socket.emit(ACTIONS.CALL, {
          to,
          roomId
      });

      history.push(`/room/${roomId}`);
  }

  const requestToJoin = (to) => {
      socket.emit(ACTIONS.REQUEST_TO_JOIN, {
          to,
      });

      window.alert('Your request is sent')
  }

  const clearCache = () => {
      localStorage.removeItem('name')
      window.location.reload();
  }

  return (
    <div ref={rootNode}>
        <h4>I am: {myName}</h4>
        <h4>My socket id: {mySocketId}</h4>
      <h2>Users online:</h2>

      <ul>
        {connectedClients.filter(item => item.socketId !== mySocketId).map(client => (
          <li key={client.socketId}>
            {client.nickName}

              {client.isInCall ? (
                  <>
                      <span style={{color: 'red'}}>In call</span>
                      <button onClick={() => requestToJoin(client.socketId)}>REQUEST TO JOIN</button>
                  </>
              ) : (
                  <button onClick={() => call(client.socketId)}>CALL</button>
              )}

          </li>
        ))}
      </ul>

        <button style={{marginTop: 400}} onClick={clearCache}>clear cache and reload</button>
    </div>
  );
}
