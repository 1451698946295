import {useParams} from 'react-router';
import useWebRTC, {LOCAL_VIDEO} from '../../hooks/useWebRTC';
import socket from "../../socket";
import ACTIONS from "../../socket/actions";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";

export default function Room() {
  const history = useHistory();
  const {id: roomID} = useParams();
  const {clients, provideMediaRef, toggleCamera, toggleMic, isCameraRecording, isMicRecording} = useWebRTC(roomID);

  useEffect(() => {
      socket.on(ACTIONS.CALL_DENIED, ({from}) => {
          window.focus();
          window.alert(`${from.nickName} denied your call`);

          leave();
      })

      socket.on(ACTIONS.REQUEST_TO_JOIN, ({from}) => {
          window.focus();
          const isAccepted = window.confirm(`${from.nickName} is requesting to join this call. Press OK to accept`);

          socket.emit(ACTIONS.REQUEST_TO_JOIN_RESPONSE, {isAccepted, to: from.socketId});
      })

      return () => {
          socket.off(ACTIONS.CALL_DENIED)
          socket.off(ACTIONS.REQUEST_TO_JOIN)
      }
  }, [])
  const leave = () => {
      socket.emit(ACTIONS.LEAVE);

      history.push('/');
  }

  return (
      <>
          <button onClick={leave}>leave</button>
          <button onClick={toggleCamera}>{isCameraRecording ? 'turn camera OFF' : 'turn camera ON'}</button>
          <button onClick={toggleMic}>{isMicRecording ? 'turn mic OFF' : 'turn mic ON'}</button>
          <div style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexWrap: 'wrap',
              height: '100vh',
          }}>
              {clients.map((clientID) => {
                  return (
                      <div key={clientID} style={{width: '30%', height: '30%'}} id={clientID}>
                          <video
                              width='100%'
                              height='100%'
                              ref={instance => {
                                  provideMediaRef(clientID, instance);
                              }}
                              autoPlay
                              playsInline
                              muted={clientID === LOCAL_VIDEO}
                          />
                      </div>
                  );
              })}
          </div>
      </>
  );
}
