const ACTIONS = {
  INIT_CONNECT: 'init-connect',
  CALL: 'call',
  REQUEST_TO_JOIN: 'request-to-join',
  REQUEST_TO_JOIN_RESPONSE: 'request-to-join-response',
  CALL_DENIED: 'call-denied',

  TOGGLE_CAMERA: 'toggle-camera',
  TOGGLE_MIC: 'toggle-mic',

  JOIN: 'join',
  LEAVE: 'leave',
  SHARE_ROOMS: 'share-rooms',
  SHARE_CLIENTS: 'share-clients',
  ADD_PEER: 'add-peer',
  REMOVE_PEER: 'remove-peer',
  RELAY_SDP: 'relay-sdp',
  RELAY_ICE: 'relay-ice',
  ICE_CANDIDATE: 'ice-candidate',
  SESSION_DESCRIPTION: 'session-description'
};

module.exports = ACTIONS;
